import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./index.css";
import Select, { NonceProvider } from "react-select";
import FormHeader from "./FormHeader";
import FormFooter from "./FormFooter";
import { ReactComponent as Image27 } from "../Connect/img/Load.svg";
import BannerSection from "./BannerSection";
import Clock from "../../images/clock.svg";
import Vector from "../../../ForgotPass/images/error.svg";
import { GooglePlacesSelect } from "../../../../shared/components/googlePlaces";
// import TopLogo from "./images/top-logo.svg";
// import TopLogo from "../FormNew/images/top-logo.svg";

const Forms = ({ authentication_state }) => {
  const authentication_ref = useRef(authentication_state);
  authentication_ref.current = authentication_state;
  const [screen, setScreen] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [location, setLocation] = useState(null);
  const [expertise, setExpertise] = useState(null);
  const [role, setRole] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [workFor, setWorkFor] = useState("");
  const [marketPlaces, setMarketPlaces] = useState([]);
  const [typeOfwork, setTypeOfWork] = useState([]);
  const [remoteWork, setRemoteWork] = useState([]);
  const [career, setCareer] = useState([]);
  const [stage, setStage] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [FormLoading, setFormLoading] = useState(false);
  // const [likeToWork, setLikeToWork] = useState([]);
  const [boardDirector, setBoardDirector] = useState([]);
  const [textArea, setTestArea] = useState("");
  const [email, setEmail] = useState("");
  const [referredBy, setreferredBy] = useState("");
  const [gender, setGender] = useState(null);
  const [Ethnicity, setEthnicity] = useState(null);
  const [terms, setTerm] = useState("");
  const [CharCount, setCharCount] = useState(0);
  const [file, setFiles] = useState();
  const [PrimeName, setPrimeName] = useState();
  const params = useParams();
  const OrgName = params.name;
  // Validation rules
  const feedRegex = /feed/;

  const linkedinRegex = /linkedin\.com/;
  const httpRegex = /^(https?:\/\/)/;

  //Errors States are here ----->
  const [firstenameError, setFirstNameError] = useState("");
  const [lastnameError, setLastNameError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [expertiseError, setExpertiseError] = useState("");
  const [roleError, setRoleError] = useState("");
  const [workForError, setWorkForError] = useState("");
  const [boardDirectorError, setBoardDirectorError] = useState("");
  const [linkedInError, setLinkedInError] = useState("");
  const [referredByError, setreferredByError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [marketPlacesError, setMarketPlacesError] = useState("");
  const [careerError, setCareerError] = useState("");
  const [typeOfworkError, setTypeOfworkError] = useState("");
  const [remoteWorkError, setRemoteWorkError] = useState("");
  const [stageError, setStageError] = useState("");
  const [industriesError, setIndustriesError] = useState("");
  // const [likeToWorkError, setLikeToWorkError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [EthnicityError, setEthnicityError] = useState("");
  const [TNCError, setTNCError] = useState("");

  // dynamic form collection
  const [formColor, setFormColor] = useState("");
  const [formHeadline, setFormHeadline] = useState("");
  const [formHeadline2, setFormHeadline2] = useState("");
  const [formLogo, setFormLogo] = useState("");
  const [formLogoHead, setFormLogoHead] = useState("");
  const [formEthenCity, setFormEthenCity] = useState();
  const [formBuzzModel, setFormBuzzModel] = useState();
  const [formIndustries, setFormIndustries] = useState();
  const [formStages, setFormStages] = useState();
  const [formVCname, setFormVcName] = useState("");
  const [privacyPolicy, setprivacyPolicy] = useState("");
  const FullStageModel = formStages?.split(',')?.map(option => option?.trim())
  const FullIndusModel = formIndustries?.split(',')?.map(option => option?.trim())
  const FullBuzzModel = formBuzzModel?.split(',')?.map(option => option?.trim())

  const EthenCityOption = formEthenCity?.toString()?.split(",");
    let UniEthencityString = EthenCityOption?.filter((c, index) => {
    return EthenCityOption?.indexOf(c) === index;
  });
  const UniEthencityStringresult = UniEthencityString?.reduce(
    (acc, currItem) => {
      if (currItem !== "") {
        acc.push({ currItem });
      }
      return acc;
    },
    []
  );

  // let likeToWorkArray = [];
  // const likeToWorkArrayMap = likeToWork?.map((item) => {
  //   likeToWorkArray.push(item.currItem);
  // });

  // Location Array
  let uniqueLocationresult = [
    { currItem: "London" },
    { currItem: "Cambridge" },
    { currItem: "Other Location (UK)" },
    { currItem: "San Fran / Bay Area" },
    { currItem: "New York" },
    { currItem: "Los Angeles" },
    { currItem: "Boston" },
    { currItem: "Other Location (US)" },
    { currItem: "Paris" },
    { currItem: "Berlin" },
    { currItem: "Other Location (Europe)" },
    { currItem: "Other Location (Asia)" },
  ];

  // Gender Array
  let GenderArray = [
    { currItem: "Female" },
    { currItem: "Male" },
    { currItem: "Transgender" },
    { currItem: "Non-binary" },
    { currItem: "Prefer not to say" },
  ];

  // Stages Array
  let EthencityArrays = [
    { currItem: "Black or African American" },
    { currItem: "Hispanic or Latino" },
    { currItem: "Middle Eastern" },
    { currItem: "South Asian" },
    { currItem: "East Asian" },
    { currItem: "White" },
    { currItem: "Prefer not to say" },
  ];

  // Desired Location Array
  let UniDesiredLocStringresult = [
    { currItem: "London" },
    { currItem: "Cambridge" },
    { currItem: "Other Location (UK)" },
    { currItem: "San Fran / Bay Area" },
    { currItem: "New York" },
    { currItem: "Los Angeles" },
    { currItem: "Boston" },
    { currItem: "Other Location (US)" },
    { currItem: "Paris" },
    { currItem: "Berlin" },
    { currItem: "Other Location (Europe)" },
    { currItem: "Other Location (Asia)" },
  ];

  // Expertise Array
  let ExpertiseMapStringresult = [
    { currItem: "Biz Ops & Strategy" },
    { currItem: "Product" },
    { currItem: "Commercial" },
    { currItem: "Growth & Marketing" },
    { currItem: "People & Talent" },
    { currItem: "Customer Success" },
    { currItem: "Finance" },
    { currItem: "Legal" },
    { currItem: "Risk & Compliance" },
    { currItem: "Software Engineering" },
    { currItem: "Data Science / Machine Learning" },
    { currItem: "BI / Data Analytics" },
    { currItem: "Procurement & Supply Chain" },
    { currItem: "Administrative" },
  ];

  useEffect(() => {
    const headerData = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios.get(`/api/user/get/${params.id}/`, headerData).then((res) => {
      setPrimeName(res.data.data.access);
    });
    if(OrgName == "GVCTN"){
      axios
      .get(
        `/api/venturecapitalforms/venturecapitalforms/get/companyname/Global VC Talent Network/`,
        headerData
      )
      .then((res) => {
        setFormColor(res?.data?.data[0]?.form_colour);
        setFormHeadline(res?.data?.data[0]?.form_description_headerline);
        setFormHeadline2(res?.data?.data[0]?.form_description);
        setFormLogo(res?.data?.data[0]?.form_logo);
        setFormLogoHead(res?.data?.data[0]?.form_logo_header);
        setFormEthenCity(res?.data?.data[0]?.ethnicity_options);
        setFormBuzzModel(res?.data?.data[0]?.biz_model_options);
        setFormIndustries(res?.data?.data[0]?.industry_options);
        setFormStages(res?.data?.data[0]?.stage_options);
        setFormVcName(res?.data?.data[0]?.vc_name);
        setprivacyPolicy(res?.data?.data[0]?.privacy_policy_url);
      });
    }else{
      axios
      .get(
        `/api/venturecapitalforms/venturecapitalforms/get/${params.id}/`,
        headerData
      )
      .then((res) => {
        setFormColor(res?.data?.data?.form_colour);
        setFormHeadline(res?.data?.data?.form_description_headerline);
        setFormHeadline2(res?.data?.data?.form_description);
        setFormLogo(res?.data?.data?.form_logo);
        setFormLogoHead(res?.data?.data?.form_logo_header);
        setFormEthenCity(res?.data?.data?.ethnicity_options);
        setFormBuzzModel(res?.data?.data?.biz_model_options);
        setFormIndustries(res?.data?.data?.industry_options);
        setFormStages(res?.data?.data?.stage_options);
        setFormVcName(res?.data?.data?.vc_name);
        setprivacyPolicy(res?.data?.data?.privacy_policy_url);
      });
    }
  }, []);

  useEffect(() => {
    if (PrimeName == "noaccess") {
      window.location.href = "/";
    }
  }, [PrimeName]);

  const handleClickPrev = (e) => {
    e.preventDefault();
    setScreen((currPage) => currPage - 1);
    window.scrollTo(0, 0);
  };

  const handleBoardExperience = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setBoardDirector([...boardDirector, value]);
    } else {
      setBoardDirector(boardDirector.filter((e) => e !== value));
    }
  };

  const handleMarketPlace = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setMarketPlaces([...marketPlaces, value]);
    } else {
      setMarketPlaces(marketPlaces.filter((e) => e !== value));
    }
  };

  const handleCareerWork = (event) => {
    const checkboxValue = event.target.value;

    if (event.target.checked) {
      // Add the checkbox value to selectedCheckboxes if the limit is not reached
      if (career.length < 4) {
        setCareer([...career, checkboxValue]);
      } else {
        // Prevent selecting more checkboxes if the limit is reached
        event.target.checked = false;
      }
    } else {
      // Remove the checkbox value from selectedCheckboxes
      setCareer(career.filter((value) => value !== checkboxValue));
    }
  };

  // const handleCareerWork = (e) => {
  //   const { value, checked } = e?.target;
  //   if (checked) {
  //     setCareer([...career, value]);
  //   } else {
  //     setCareer(career.filter((e) => e !== value));
  //   }
  // }

  const handleCheckboxChange = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setTypeOfWork([...typeOfwork, value]);
    } else {
      setTypeOfWork(typeOfwork.filter((e) => e !== value));
    }
  };

  const handleRemoteboxChange = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setRemoteWork([...remoteWork, value]);
    } else {
      setRemoteWork(remoteWork.filter((e) => e !== value));
    }
  };

  const handleStagesArray = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setStage([...stage, value]);
    } else {
      setStage(stage.filter((e) => e !== value));
    }
  };

  const handleIndustries = (e) => {
    const { value, checked } = e?.target;
    if (checked) {
      setIndustries([...industries, value]);
    } else {
      setIndustries(industries.filter((e) => e !== value));
    }
  };

  const handleCharCount = (e) => {
    const inputName = e.target.value;
    const resetCount = 0 + inputName?.length;
    setTestArea(inputName);
    setCharCount(resetCount > 0 ? resetCount : 0);
  };

  const handleClickNextscreen1 = (e) => {
    e.preventDefault();
    if (screen == 1 && firstName == "") {
      setFirstNameError("Please add your First Name");
    } else if (screen == 1 && lastName == "") {
      setLastNameError("Please add your Last Name");
    } else if (screen == 1 && location == null) {
      setLocationError("Please add your Current Location");
    } else if (screen == 1 && linkedIn == "") {
      setLinkedInError("Please add your LinkedIn Profile");
    } else if (
      screen == 1 &&
      linkedIn !== "" &&
      !linkedinRegex.test(linkedIn)
    ) {
      setLinkedInError(
        "This doesn’t look quite right, please check it’s your personal LinkedIn profile URL"
      );
    } else if (screen == 1 && linkedIn !== "" && linkedIn.includes("feed")) {
      setLinkedInError(
        "This doesn’t look quite right, please check it’s your personal LinkedIn profile URL"
      );
    } else if (
      screen == 1 &&
      linkedIn !== "" &&
      linkedIn.startsWith("http") &&
      !httpRegex.test(linkedIn)
    ) {
      setLinkedInError(
        "This doesn’t look quite right, please check it’s your personal LinkedIn profile URL"
      );
    } else if (screen == 1 && email == "") {
      setEmailError("Please add your Email Address");
    } else if (screen == 1 && email != "" && !email.includes("@")) {
      setEmailError("Please add valid Email Address");
    } else if (screen == 1 && gender == null) {
      setGenderError("Please add your Gender Identity");
    } else if (screen == 1 && Ethnicity == null) {
      setEthnicityError("Please add your Ethnicity");
    } else {
      setScreen((currPage) => currPage + 1);
      window.scrollTo(0, 0);
    }
  };

  const handleClickNext = (e) => {
    e.preventDefault();
    if (screen == 2 && role == "") {
      setRoleError("Please add your most recent Role");
    } else if (screen == 2 && workFor == "") {
      setWorkForError("Please add your most recent Employer");
    } else if (screen == 2 && expertise == null) {
      setExpertiseError("Please add your main Professional Expertise");
    } else if (screen == 2 && boardDirector.length == 0) {
      setBoardDirectorError("Please let us know if you have served on a Board");
    } else if (screen == 2 && marketPlaces.length == 0) {
      setMarketPlacesError("Please select a business model");
    } else if (screen == 2 && industries.length == 0 && OrgName != "BTV") {
      setIndustriesError("Please select your industry preferences");
    } else {
      setScreen((currPage) => currPage + 1);
      window.scrollTo(0, 0);
    }
  };

  const isValid = () => {
    if (screen == 3 && career.length == 0) {
      setCareerError("Please let us know which Career Level you are targeting");
      return false;
    } else if (screen == 3 && typeOfwork.length == 0) {
      setTypeOfworkError("Please add your Work Schedule preferences");
      return false;
    } else if (screen == 3 && remoteWork.length == 0) {
      setRemoteWorkError("Please add your Remote Work preferences");
      return false;
    } else if (screen == 3 && stage.length == 0) {
      setStageError("Please add your Company Stage preferences");
      return false;
    } else if (screen == 3 && referredBy == "") {
      setreferredByError(
        "Please add the email address of the person who shared this form with you or use" + formVCname.toLowerCase().replace(/\s/g, '') + "@resumedrop.com"
      );
      return false;
    } else if (screen == 3 && referredBy != "" && !referredBy.includes("@")) {
      setreferredByError("Please add valid Email Address");
      return false;
    } else if (screen == 3 && referredBy == email) {
      setreferredByError("Your referral email cannot be your email address");
      return false;
    } else if (screen == 3 && terms == false) {
      setTNCError("Please Check Terms and Conditions");
      return false;
    } else if (screen == 3 && referredBy != "" && !referredBy.includes("@")) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (locationError) {
      const errorFieldElement = document?.getElementById("location-field");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (firstenameError) {
      const errorFieldElement = document?.getElementById("firstName");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (lastnameError) {
      const errorFieldElement = document?.getElementById("lastName");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (locationError) {
      const errorFieldElement = document?.getElementById("Location");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (expertiseError) {
      const errorFieldElement = document?.getElementById("Expertise");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (roleError) {
      const errorFieldElement = document?.getElementById("role");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (careerError) {
      const errorFieldElement = document?.getElementById("mid");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (typeOfworkError) {
      const errorFieldElement = document?.getElementById("Full-Time");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (remoteWorkError) {
      const errorFieldElement = document?.getElementById("No-remote");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (stageError) {
      const errorFieldElement = document?.getElementById("Pre-Seed");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (genderError || EthnicityError) {
      const errorFieldElement = document?.getElementById("Gender-Error");
      errorFieldElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [
    locationError,
    firstenameError,
    lastnameError,
    locationError,
    stageError,
    remoteWorkError,
    expertiseError,
    roleError,
    careerError,
    typeOfworkError,
    genderError,
    EthnicityError,
  ]);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileInputChange = (e) => {
    let file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        setFiles({
          base64URL: result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    setFiles({
      file: e.target.files[0],
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      setFormLoading(true);
      const data = {
        firstname: firstName,
        lastname: lastName,
        created_by: params.id,
        access: OrgName == "StartupLane" ? "premium" : "basic",
        email: email,
        profilepic: null,
        about: {
          candidate_description: textArea,
          // nativeplace: "London",
          currentLocation: location,
          functionalexpertise: expertise?.currItem,
          gender: gender.currItem,
          ethnicity: Ethnicity.currItem,
          linkedin: linkedIn,
          worktype: typeOfwork,
        },
        careerplan: {
          kind_of_business: marketPlaces.toString(),
          work: role,
          employer: workFor,
          careerlevel: career.toString(),
          capital:formVCname,
          employer: workFor,
          // industry: null,
          industry_experience:
            OrgName == "BTV" ? "Fintech" : industries.toString(),
          stage: stage.toString(),
          // workplace: likeToWorkArray.join(","),
          remote: remoteWork.toString(),
          board_experience: boardDirector.toString(),
          work_preference: workFor,
        },
        referred_by: referredBy ? referredBy : null,
        cv:
          file?.file?.base64.replace("data:application/pdf;base64,", "") ||
          null,
        ext: file?.file?.type.replace("application/", ".") || null,
        finalbits: {
          document: null,
          extrainformation: textArea,
          termsandconditions: terms,
        },
      };
      const headerData = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios
        .post(`/api/candidate/candidate/`, data, headerData)
        .then((res) => {
          if (res.status == 201 || res.status == 200) {
            setScreen((currPage) => currPage + 1);
          }
        })
        .finally(() => {
          setFormLoading(false);
        });
      // .catch((error) => {
      //   toast.error(error.message, {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      //   setLoading(false);
      // });
    }
  };

  const handlePrivacyPolicy = () => {
    if (OrgName == "OctopusVentures") {
      window.open("https://octopusventures.com/privacy-policy/");
    } else if (OrgName == "AnzuPartners") {
      window.open("https://www.anzupartners.com/privacy/");
    } else {
      window.open("https://www.startuplane.io/legal/privacy");
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid red",
      // This line disable the blue border
      boxShadow: "red",
    }),
  };

  const handleTerm = (e) => {
    const { checked } = e?.target;
    if (checked) {
      setTerm(checked);
    } else {
      setTerm(false);
    }
  };

  return (
    <>
    {formColor !== "" && (
      <>
      <Helmet>
        <title>
          {`${formVCname} Candidate Form`}
        </title>
      </Helmet>
      <div className="FullBody">
        <FormHeader
          OrgName={OrgName}
          formLogoHead={formLogoHead}
          formColor={formColor}
          privacyPolicy={privacyPolicy}
          formVCname={formVCname}
        />
        {screen != 4 && (
          <section
          class="about-top"
          style={formColor !== "" ? { backgroundColor: `${formColor}` } : null}
        >
          <div class="about-top-inner">
            <div class="about-top-logo">
              <img width={"100px"} height={"100px"} src={formLogo} alt="" />
            </div>
            <div class="about-top-head">
              <h2>{formHeadline}</h2>
              <p>{formHeadline2}</p>
            </div>
          </div>
        </section>
        )}
        {/* {screen != 4 && (
        <BannerSection screen={screen} OrgName={OrgName} />
      )} */}
        <div className="row justify-content-center">
          <div className="form-div">
            {screen != 4 && (
              <div className="section-title">
                <h2>About you...</h2>
              </div>
            )}
            {screen == 1 && (
              <div className="forom-box">
                <div className="tab-list">
                  <ul>
                    <li
                      className="active"
                      // style={
                      //   formColor !== ""
                      //     ? { borderBottom: `4px solid ${formColor}`, borderRadius: '0 0 4px 4px' }
                      //     : null
                      // }
                    >
                      <a href="#">
                        <span style={{ color: `${formColor}` }}>
                          <i class="fa fa-circle" aria-hidden="true"></i>
                        </span>
                        Who you Are{" "}
                      </a>
                      <hr style={{height: '4px', borderRadius: '4px', color: `${formColor}`, margin: '14px 0', border: `1px solid ${formColor}`, backgroundColor: `${formColor}`}} />
                    </li>
                    <li>
                      <a href="#">
                        <span>
                          <i class="fa fa-circle" aria-hidden="true"></i>
                        </span>
                        Your Experience{" "}
                      </a>
                      <hr style={{height: '4px', borderRadius: '4px', color: `#e6e9f0`, margin: '14px 0', border: `1px solid #e6e9f0`, backgroundColor: `#e6e9f0`}} />
                    </li>
                    <li>
                      <a href="#">
                        <span>
                          <i class="fa fa-circle" aria-hidden="true"></i>
                        </span>
                        Your Preferences{" "}
                      </a>
                      <hr style={{height: '4px', borderRadius: '4px', color: `#e6e9f0`, margin: '14px 0', border: `1px solid #e6e9f0`, backgroundColor: `#e6e9f0`}} />
                    </li>
                  </ul>
                </div>
                <div className="formbg relative">
                  <form id="">
                    <div
                      className="circle"
                      style={
                        formColor !== ""
                          ? { backgroundColor: `${formColor}` }
                          : null
                      }
                    >
                      <img src={Clock} />
                      <div>
                        <b>Estimated:</b>
                      </div>
                      <div>3 mins left</div>
                    </div>
                    <div className="d-sm-flex justify-content-between align-items-center col-gap-21 flex-wrap">
                      <div className="flex1 mb-3">
                        <label for="">First name</label>
                        <input
                          type="text"
                          id="firstName"
                          value={firstName}
                          onChange={(e) => {
                            const result =
                              e.target.value.charAt(0).toUpperCase() +
                              e.target.value.slice(1);
                            setFirstName(result);
                          }}
                          placeholder="Jane"
                          style={{
                            // padding: '10px',
                            border:
                              firstenameError !== "" && firstName === ""
                                ? "1px solid red"
                                : "1px solid rgb(230, 233, 240)",
                            transition: "border-color 0.3s ease-in-out",
                            outline: "none",
                            boxShadow: `0 0 0 3px transparent`,
                          }}
                          onFocus={(e) => {
                            e.target.style.borderColor = `${formColor}`; // Change border color on focus
                            e.target.style.boxShadow = `${formColor}`; // Change focus ring color on focus
                          }}
                          onBlur={(e) => {
                            e.target.style.borderColor = "rgb(230, 233, 240)"; // Reset border color on blur
                            e.target.style.boxShadow = "0 0 0 3px transparent"; // Reset focus ring color on blur
                          }}
                          className={
                            firstenameError !== "" && firstName === ""
                              ? "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-500 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                              : "appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:bg-white transition ease-in-out"
                          }
                        />

                        {firstenameError != "" && firstName == "" && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{firstenameError}</h5>
                          </div>
                        )}
                        {/* <input type="text" class="form-control inputtext" id="" placeholder="Jane" /> */}
                      </div>
                      <div className="flex1 mb-3">
                        <label for="">Last name</label>
                        <input
                          type="text"
                          id="lastName"
                          value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                          style={{
                            // padding: '10px',
                            border:
                            lastnameError != "" && lastName == ""
                                ? "1px solid red"
                                : "1px solid rgb(230, 233, 240)",
                            transition: "border-color 0.3s ease-in-out",
                            outline: "none",
                            boxShadow: `0 0 0 3px transparent`,
                          }}
                          onFocus={(e) => {
                            e.target.style.borderColor = `${formColor}`; // Change border color on focus
                            e.target.style.boxShadow = `${formColor}`; // Change focus ring color on focus
                          }}
                          onBlur={(e) => {
                            e.target.style.borderColor = "rgb(230, 233, 240)"; // Reset border color on blur
                            e.target.style.boxShadow = "0 0 0 3px transparent"; // Reset focus ring color on blur
                          }}
                          placeholder="Doe"
                          className={
                            lastnameError != "" && lastName == ""
                              ? "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-500 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                              : "appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:bg-white transition ease-in-out"
                          }
                        />
                        {lastnameError != "" && lastName == "" && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{lastnameError}</h5>
                          </div>
                        )}
                        {/* <input type="text" className="form-control inputtext" id="" placeholder="Doe" /> */}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-12 mb-3">
                        <label for="exampleFormControlSelect1">
                          Where do you <b>live?</b>
                        </label>
                        <GooglePlacesSelect
                          value={location}
                          onChange={(location) => setLocation(location)}
                          placeholder={"Type your location"}
                          form={"formCandidate"}
                          formColor={formColor}
                        />
                        {locationError != "" && location == null && (
                          <div
                            className="inline-flex items-baseline mt-2"
                            id="location-field"
                          >
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{locationError}</h5>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-8">
                        <label for="">
                          What’s your LinkedIn <b>profile?</b>
                        </label>
                        <input
                          type="url"
                          value={linkedIn}
                          autoComplete="off"
                          onChange={(e) => {
                            setLinkedIn(e.target.value);
                          }}
                          style={{
                            // padding: '10px',
                            border:
                            linkedInError != "" && linkedIn == "" ||
                            linkedInError != "" &&
                              linkedIn != "" &&
                              !linkedinRegex.test(linkedIn) ||
                              linkedInError != "" &&
                              linkedIn != "" &&
                              linkedIn.includes("feed") ||
                              linkedInError != "" &&
                              linkedIn != "" &&
                              linkedIn.startsWith("http") &&
                              !httpRegex.test(linkedIn)
                                ? "1px solid red"
                                : "1px solid rgb(230, 233, 240)",
                            transition: "border-color 0.3s ease-in-out",
                            outline: "none",
                            boxShadow: `0 0 0 3px transparent`,
                          }}
                          onFocus={(e) => {
                            e.target.style.borderColor = `${formColor}`; // Change border color on focus
                            e.target.style.boxShadow = `${formColor}`; // Change focus ring color on focus
                          }}
                          onBlur={(e) => {
                            e.target.style.borderColor = "rgb(230, 233, 240)"; // Reset border color on blur
                            e.target.style.boxShadow = "0 0 0 3px transparent"; // Reset focus ring color on blur
                          }}
                          className={
                            (linkedInError != "" && linkedIn == "") ||
                            (linkedInError != "" &&
                              linkedIn != "" &&
                              !linkedinRegex.test(linkedIn)) ||
                            (linkedInError != "" &&
                              linkedIn != "" &&
                              linkedIn.includes("feed")) ||
                            (linkedInError != "" &&
                              linkedIn != "" &&
                              linkedIn.startsWith("http") &&
                              !httpRegex.test(linkedIn))
                              ? "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-500 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                              : "appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:bg-white transition ease-in-out"
                          }
                          id="linkedIn"
                          placeholder="www.linkedin.com/in/..."
                        />
                        {(linkedInError != "" && linkedIn == "") ||
                        (linkedInError != "" &&
                          linkedIn != "" &&
                          !linkedinRegex.test(linkedIn)) ||
                        (linkedInError != "" &&
                          linkedIn != "" &&
                          linkedIn.includes("feed")) ||
                        (linkedInError != "" &&
                          linkedIn != "" &&
                          linkedIn.startsWith("http") &&
                          !httpRegex.test(linkedIn)) ? (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{linkedInError}</h5>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-row mt-19">
                      <div className="col-md-8 ">
                        <label for="">
                          and your <b>email address?</b>
                        </label>
                        <input
                          type="text"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          style={{
                            // padding: '10px',
                            border:
                            email != "" && !email.includes("@") || emailError != "" && email == ""
                                ? "1px solid red"
                                : "1px solid rgb(230, 233, 240)",
                            transition: "border-color 0.3s ease-in-out",
                            outline: "none",
                            boxShadow: `0 0 0 3px transparent`,
                          }}
                          onFocus={(e) => {
                            e.target.style.borderColor = `${formColor}`; // Change border color on focus
                            e.target.style.boxShadow = `${formColor}`; // Change focus ring color on focus
                          }}
                          onBlur={(e) => {
                            e.target.style.borderColor = "rgb(230, 233, 240)"; // Reset border color on blur
                            e.target.style.boxShadow = "0 0 0 3px transparent"; // Reset focus ring color on blur
                          }}
                          className={
                            (email != "" && !email.includes("@")) ||
                            (emailError != "" && email == "")
                              ? "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-500 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                              : "appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:bg-white transition ease-in-out"
                          }
                          id="email"
                          placeholder="me@email.com"
                        />
                        {(email != "" &&
                          emailError != "" &&
                          !email.includes("@")) ||
                        (emailError != "" && email == "") ? (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{emailError}</h5>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-row mt-5">
                      <div className="col-md-12 mb-3">
                        <label for="" className="mb-12">
                          You can select <b>prefer not to say,</b> but we’d love
                          to know:{" "}
                        </label>
                        <div
                          className="d-sm-flex align-items-center justify-content-between flex-wrap col-gap-90"
                          id="Gender-Error"
                        >
                          <div className="flex1 ">
                            <label for="" className="GenderMargin">
                              Gender Identity*
                            </label>
                            <Select
                              placeholder="Choose"
                              name="Gender"
                              isClearable={true}
                              getOptionLabel={(item) => item?.currItem}
                              getOptionValue={(item) => item?.currItem}
                              onChange={(item) => {
                                setGender(item);
                              }}
                              styles={ genderError != "" && gender == null ? {
                                control: (provided, state) => ({
                              ...provided,
                              borderColor: `red`,
                              // boxShadow: state.isFocused ? `0 0 0 1px red` : provided.boxShadow,
                              minHeight: '47px',
                              '&:hover': {
                               borderColor: `${formColor}`, // Change border color on hover
                               },
                              }),
                              indicatorsContainer: (provided) => ({
                              ...provided,
                              svg: {
                               fill: `${formColor}`, // Change the fill color of the SVG indicator
                              },
                              }),
                              } : {
                              control: (provided, state) => ({
                              ...provided,
                              borderColor: state.isFocused ? `${formColor}` : "none",
                              boxShadow: state.isFocused ? `0 0 0 1px ${formColor}` : provided.boxShadow,
                              minHeight: '47px',
                              '&:hover': {
                               borderColor: `${formColor}`, // Change border color on hover
                               },
                              }),
                              indicatorsContainer: (provided) => ({
                              ...provided,
                              svg: {
                               fill: `${formColor}`, // Change the fill color of the SVG indicator
                              },
                              }),
                              }}
                              // styles={
                              //   genderError != "" &&
                              //   gender == null &&
                              //   customStyles
                              // }
                              value={gender}
                              options={GenderArray}
                            />
                          </div>
                          <div className="flex1 ">
                            <label for="" className="GenderMargin">
                              Ethnicity*
                            </label>
                            <Select
                              placeholder="Choose"
                              name="Ethnicity"
                              isClearable={true}
                              getOptionLabel={(item) => item?.currItem}
                              getOptionValue={(item) => item?.currItem}
                              styles={ EthnicityError != "" &&
                                Ethnicity == null  ? {
                                control: (provided, state) => ({
                              ...provided,
                              borderColor: `red`,
                              // boxShadow: state.isFocused ? `0 0 0 1px red` : provided.boxShadow,
                              minHeight: '47px',
                              '&:hover': {
                               borderColor: `${formColor}`, // Change border color on hover
                               },
                              }),
                              indicatorsContainer: (provided) => ({
                              ...provided,
                              svg: {
                               fill: `${formColor}`, // Change the fill color of the SVG indicator
                              },
                              }),
                              } : {
                              control: (provided, state) => ({
                              ...provided,
                              borderColor: state.isFocused ? `${formColor}` : "none",
                              boxShadow: state.isFocused ? `0 0 0 1px ${formColor}` : provided.boxShadow,
                              minHeight: '47px',
                              '&:hover': {
                               borderColor: `${formColor}`, // Change border color on hover
                               },
                              }),
                              indicatorsContainer: (provided) => ({
                              ...provided,
                              svg: {
                               fill: `${formColor}`, // Change the fill color of the SVG indicator
                              },
                              }),
                              }}
                              // styles={
                              //   EthnicityError != "" &&
                              //   Ethnicity == null &&
                              //   customStyles
                              // }
                              onChange={(item) => {
                                setEthnicity(item);
                              }}
                              value={Ethnicity}
                              options={
                                UniEthencityStringresult
                              }
                            />
                          </div>
                        </div>
                        {genderError != "" && gender == null && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{genderError}</h5>
                          </div>
                        )}
                        {EthnicityError != "" && Ethnicity == null && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{EthnicityError}</h5>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-12 mb-3 important-para">
                        <p className="head">Why is this important?</p>
                        <br />
                        <p>
                          We want to build more inclusive teams, starting at the
                          top. To reach our goal, we need to understand a bit
                          more about who you are so we know how we’re doing.
                        </p>
                        <br />
                        <p>
                          This data is just for us and won’t be shared with
                          employers (or anyone else). Thank you for supporting
                          us in this goal.🙂🙏
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
            {screen == 2 && (
              <div class="row justify-content-center">
                <div class="form-div">
                  <div class="forom-box">
                    <div class="tab-list">
                      <ul>
                        <li>
                          <a href="#">
                            <span>
                              <i class="fa fa-circle" aria-hidden="true"></i>
                            </span>
                            Who you Are{" "}
                          </a>
                          <hr style={{height: '4px', borderRadius: '4px', color: `#e6e9f0`, margin: '14px 0', border: `1px solid #e6e9f0`, backgroundColor: `#e6e9f0`}} />
                        </li>
                        <li
                          class="active"
                          // style={
                          //   formColor !== ""
                          //     ? { borderBottom: `4px solid ${formColor}` }
                          //     : null
                          // }
                        >
                          <a href="#">
                            <span style={{ color: `${formColor}` }}>
                              <i class="fa fa-circle" aria-hidden="true"></i>
                            </span>
                            Your Experience{" "}
                          </a>
                          <hr style={{height: '4px', borderRadius: '4px', color: `${formColor}`, margin: '14px 0', border: `1px solid ${formColor}`, backgroundColor: `${formColor}`}} />
                        </li>
                        <li>
                          <a href="#">
                            <span>
                              <i class="fa fa-circle" aria-hidden="true"></i>
                            </span>
                            Your Preferences{" "}
                          </a>
                          <hr style={{height: '4px', borderRadius: '4px', color: `#e6e9f0`, margin: '14px 0', border: `1px solid #e6e9f0`, backgroundColor: `#e6e9f0`}} />
                        </li>
                      </ul>
                    </div>
                    <div class="formbg relative">
                      <form id="">
                        <div
                          className="circle"
                          style={
                            formColor !== ""
                              ? { backgroundColor: `${formColor}` }
                              : null
                          }
                        >
                          <img src={Clock} />
                          <div>
                            <b>Estimated:</b>
                          </div>
                          <div>2 mins left</div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-12 mb-3">
                            <label for="">
                              What is your most recent <b>job title?</b>
                            </label>
                            <input
                              type="text"
                              id="role"
                              value={role}
                              onChange={(e) => {
                                setRole(e.target.value);
                              }}
                              style={{
                            // padding: '10px',
                            border:
                            roleError != "" && role == ""
                                ? "1px solid red"
                                : "1px solid rgb(230, 233, 240)",
                            transition: "border-color 0.3s ease-in-out",
                            outline: "none",
                            boxShadow: `0 0 0 3px transparent`,
                          }}
                          onFocus={(e) => {
                            e.target.style.borderColor = `${formColor}`; // Change border color on focus
                            e.target.style.boxShadow = `${formColor}`; // Change focus ring color on focus
                          }}
                          onBlur={(e) => {
                            e.target.style.borderColor = "rgb(230, 233, 240)"; // Reset border color on blur
                            e.target.style.boxShadow = "0 0 0 3px transparent"; // Reset focus ring color on blur
                          }}
                              placeholder="Chief Product Officer"
                              className={
                                roleError != "" && role == ""
                                  ? "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-500 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                                  : "appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:bg-white transition ease-in-out"
                              }
                            />
                            {roleError != "" && role == "" && (
                              <div className="inline-flex items-baseline mt-2">
                                <img className="self-center" src={Vector} />
                                <h5 className="errorClass">{roleError}</h5>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-12 mb-3">
                            <label for="">
                              Who did you last <b>work for?</b>
                            </label>
                            <input
                              type="text"
                              id="employer"
                              placeholder="Acme LTD"
                              value={workFor}
                              onChange={(e) => {
                                setWorkFor(e.target.value);
                              }}
                              style={{
                            // padding: '10px',
                            border:
                            workForError != "" && workFor == ""
                                ? "1px solid red"
                                : "1px solid rgb(230, 233, 240)",
                            transition: "border-color 0.3s ease-in-out",
                            outline: "none",
                            boxShadow: `0 0 0 3px transparent`,
                          }}
                          onFocus={(e) => {
                            e.target.style.borderColor = `${formColor}`; // Change border color on focus
                            e.target.style.boxShadow = `${formColor}`; // Change focus ring color on focus
                          }}
                          onBlur={(e) => {
                            e.target.style.borderColor = "rgb(230, 233, 240)"; // Reset border color on blur
                            e.target.style.boxShadow = "0 0 0 3px transparent"; // Reset focus ring color on blur
                          }}
                              className={
                                workForError != "" && workFor == ""
                                  ? "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-500 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                                  : "appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:bg-white transition ease-in-out"
                              }
                            />
                            {workForError != "" && workFor == "" && (
                              <div className="inline-flex items-baseline mt-2">
                                <img className="self-center" src={Vector} />
                                <h5 className="errorClass">{workForError}</h5>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-12 mb-3">
                            <label for="exampleFormControlSelect1">
                              What are you <b>best at?</b>
                            </label>
                            <Select
                              placeholder="Choose Expertise"
                              name="Expertise"
                              id="Expertise"
                              className="text-base md:text-lg font-normal text-gray-800"
                              isClearable={true}
                              getOptionLabel={(item) => item?.currItem}
                              getOptionValue={(item) => item?.currItem}
                              value={expertise}
                              styles={ expertiseError != "" &&
                              expertise == null  ? {
                                control: (provided, state) => ({
                              ...provided,
                              borderColor: `red`,
                              // boxShadow: state.isFocused ? `0 0 0 1px red` : provided.boxShadow,
                              minHeight: '47px',
                              '&:hover': {
                               borderColor: `${formColor}`, // Change border color on hover
                               },
                              }),
                              indicatorsContainer: (provided) => ({
                              ...provided,
                              svg: {
                               fill: `${formColor}`, // Change the fill color of the SVG indicator
                              },
                              }),
                              } : {
                              control: (provided, state) => ({
                              ...provided,
                              borderColor: state.isFocused ? `${formColor}` : "none",
                              boxShadow: state.isFocused ? `0 0 0 1px ${formColor}` : provided.boxShadow,
                              minHeight: '47px',
                              '&:hover': {
                               borderColor: `${formColor}`, // Change border color on hover
                               },
                              }),
                              indicatorsContainer: (provided) => ({
                              ...provided,
                              svg: {
                               fill: `${formColor}`, // Change the fill color of the SVG indicator
                              },
                              }),
                              }}
                              // styles={
                              //   expertiseError != "" &&
                              //   expertise == null &&
                              //   customStyles
                              // }
                              onChange={(item) => setExpertise(item)}
                              options={ExpertiseMapStringresult}
                            />
                            {expertiseError != "" && expertise == null && (
                              <div
                                className="inline-flex items-baseline mt-2"
                                id="expert-field"
                              >
                                <img className="self-center" src={Vector} />
                                <h5 className="errorClass">{expertiseError}</h5>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-12 NedMargin">
                            <label for="">
                              Do you have any <b>Board experience</b> ?{" "}
                            </label>
                            <div className="d-sm-flex align-items-center justify-content-between flex-wrap mt-2">
                              <div className="flex1 flex140">
                                <label for="Chair" className="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    className="checkbox__input"
                                    type="checkbox"
                                    id="Chairexperience"
                                    value={"Chair"}
                                    style={{cursor: 'pointer'}}
                                    checked={boardDirector.includes("Chair")}
                                    onChange={(e) => handleBoardExperience(e)}
                                  />
                                  <svg
                                    className="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    />
                                    <path
                                      className="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    />
                                  </svg>
                                  <span className="checkbox__label">Chair</span>
                                </label>
                              </div>
                              <div className="flex1 flex140">
                                <label for="NED" className="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    className="checkbox__input"
                                    type="checkbox"
                                    id="NEDexperience"
                                    style={{cursor: 'pointer'}}
                                    value={"NED"}
                                    checked={boardDirector.includes("NED")}
                                    onChange={(e) => handleBoardExperience(e)}
                                  />
                                  <svg
                                    className="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    />
                                    <path
                                      className="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    />
                                  </svg>
                                  <span className="checkbox__label">NED</span>
                                </label>
                              </div>
                              <div className="flex1 flex140">
                                <label for="BoardAdvisor" className="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    className="checkbox__input"
                                    type="checkbox"
                                    id="BoardAdvisorexperience"
                                    style={{cursor: 'pointer'}}
                                    value={"Board Advisor"}
                                    checked={boardDirector.includes(
                                      "Board Advisor"
                                    )}
                                    onChange={(e) => handleBoardExperience(e)}
                                  />
                                  <svg
                                    className="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    />
                                    <path
                                      className="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    />
                                  </svg>
                                  <span className="checkbox__label">
                                    Board Advisor
                                  </span>
                                </label>
                              </div>
                              <div className="flex1 flex140">
                                <label for="NO" className="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    className="checkbox__input"
                                    type="checkbox"
                                    id="NOexperience"
                                    style={{cursor: 'pointer'}}
                                    value={"No Board Experience"}
                                    checked={boardDirector.includes(
                                      "No Board Experience"
                                    )}
                                    onChange={(e) => handleBoardExperience(e)}
                                  />
                                  <svg
                                    className="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    />
                                    <path
                                      className="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    />
                                  </svg>
                                  <span className="checkbox__label">
                                    No Board Experience
                                  </span>
                                </label>
                              </div>
                            </div>
                            {boardDirectorError != "" &&
                              boardDirector.length == 0 && (
                                <div className="inline-flex items-baseline mt-2">
                                  <img className="self-center" src={Vector} />
                                  <h5 className="errorClass">
                                    {boardDirectorError}
                                  </h5>
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-12 mb-3">
                            <div className="mb-23">
                              <label for="">
                                What <b>kinds of businesses</b> have you worked
                                for?{" "}
                              </label>
                            </div>
                            <div className="d-sm-flex align-items-center flex-wrap">
                            {FullBuzzModel?.map((place, index) => (
                            <div className="w-full sm:w-1/2 md:w-1/3" key={place}>
      
          <label htmlFor={place} className="checkbox" style={{cursor: 'default'}}>
            <input
              className="checkbox__input"
              type="checkbox"
              id={place + "businesses"}
              style={{cursor: 'pointer'}}
              value={place}
              checked={marketPlaces.includes(place)}
              onChange={handleMarketPlace}
            />
            <svg
              className="checkbox__icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 22 22"
            >
              <rect
                width="21"
                height="21"
                x=".5"
                y=".5"
                fill="#FFF"
                stroke="transparent"
                rx="3"
              />
              <path
                className="tick"
                stroke={`${formColor}`} // Assuming formColor is defined
                fill="none"
                strokeLinecap="round" // Corrected attribute name
                strokeWidth="1" // Corrected attribute name
                d="M4 10l5 5 9-9"
              />
            </svg>
            <span className="checkbox__label">{place}</span>
          </label>
        </div>
  ))}
                            </div>
                            {marketPlacesError != "" &&
                              marketPlaces.length == 0 && (
                                <div className="inline-flex items-baseline mt-2">
                                  <img className="self-center" src={Vector} />
                                  <h5 className="errorClass">
                                    {marketPlacesError}
                                  </h5>
                                </div>
                              )}
                          </div>
                        </div>
                          <div class="form-row">
                            <div class="col-md-12 mb-3">
                              <div class="mb-23">
                                <label for="">
                                  Which <b>industries</b> do you have experience
                                  in?
                                </label>
                              </div>
                              <div class="d-sm-flex align-items-center flex-wrap">
                                {FullIndusModel?.map((place, index) => (
                                  <div class="w-full sm:w-1/2 md:w-1/3" key={place}>
                                    <label htmlFor={place} className="checkbox" style={{cursor: 'default'}}>
                                      <input
                                        className="checkbox__input"
                                        type="checkbox"
                                        id={place + "industries"}
                                        style={{cursor: 'pointer'}}
                                        value={place}
                                        checked={industries.includes(place)}
                                        onChange={(e) => handleIndustries(e)}
                                      />
                                      <svg
                                        className="checkbox__icon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                      >
                                        <rect
                                          width="21"
                                          height="21"
                                          x=".5"
                                          y=".5"
                                          fill="#FFF"
                                          stroke="transparent"
                                          rx="3"
                                        />
                                        <path
                                          className="tick"
                                          stroke={`${formColor}`} // Assuming formColor is defined
                                          fill="none"
                                          strokeLinecap="round" // Corrected attribute name
                                          strokeWidth="1" // Corrected attribute name
                                          d="M4 10l5 5 9-9"
                                        />
                                      </svg>
                                      <span className="checkbox__label">
                                        {place}
                                      </span>
                                    </label>
                                  </div>
                                ))}
                              </div>
                              {industriesError != "" &&
                              industries.length == 0 && (
                                <div className="inline-flex items-baseline mt-2">
                                  <img className="self-center" src={Vector} />
                                  <h5 className="errorClass">
                                    {industriesError}
                                  </h5>
                                </div>
                              )}
                            </div>
                          </div>
                        <div className="form-row">
                          <div className="col-md-12 text-center">
                            <label style={{ marginBottom: "16px" }}>
                              We’d love a copy of your CV if you have one
                            </label>
                            <div className="button_outer">
                              <div className="btn_upload" style={{color: `${formColor}`}}>
                                <input
                                  type="file"
                                  id="file"
                                  accept=".pdf"
                                  size="10"
                                  placeholder="Other information"
                                  onChange={(e) => handleFileInputChange(e)}
                                />
                                <span>
                                  <i
                                    className="fa fa-paperclip"
                                    aria-hidden="true"
                                  ></i>
                                </span>{" "}
                                {file?.file?.name
                                  ? file?.file?.name
                                  : "Choose PDF file"}
                              </div>
                              <div className="processing_bar"></div>
                              <div className="success_box"></div>
                            </div>
                            {file?.file?.type != "application/pdf" && file && (
                              <p className="text-sm md:text-base text-red-800 mb-2">
                                *Please select PDF format of your CV only
                              </p>
                            )}
                          </div>
                        </div>
                        {/* <div class="form-row">
                        <div class="col-md-8 mb-3">
                          <label for="">
                            Where would you like to <b>work?</b>
                          </label>
                          <Select
                            placeholder="Choose Location(s)"
                            name="Location"
                            className="text-base md:text-lg font-normal text-gray-800"
                            isClearable={true}
                            isMulti
                            getOptionLabel={(item) => item?.currItem}
                            getOptionValue={(item) => item?.currItem}
                            onChange={(item) => {
                              setLikeToWork(item);
                            }}
                            styles={
                              likeToWorkError != "" &&
                              likeToWork == "" &&
                              customStyles
                            }
                            value={likeToWork}
                            options={UniDesiredLocStringresult}
                          />
                          {likeToWorkError != "" && likeToWork == "" && (
                          <div className="inline-flex items-baseline mt-2">
                            <img className="self-center" src={Vector} />
                            <h5 className="errorClass">{likeToWorkError}</h5>
                          </div>
                        )}
                        </div>
                      </div> */}
                      </form>
                    </div>
                  </div>
                  {screen == 2 && (
                    <div class="col-md-12 mb-5 ">
                      <div class="d-sm-flex justify-content-center btn-gap text-center ">
                        <div>
                          <button
                            class="btn back"
                            type="submit"
                            style={{color: `${formColor}`}}
                            onClick={(e) => handleClickPrev(e)}
                          >
                            Back
                          </button>
                        </div>
                        <div>
                          <button
                            class="btn submit"
                            type="submit"
                            style={{backgroundColor: `${formColor}`}}
                            onClick={(e) => handleClickNext(e)}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {screen == 3 && (
              <div class="row justify-content-center">
                <div class="form-div">
                  <div class="forom-box">
                    <div class="tab-list">
                      <ul>
                        <li>
                          <a href="#">
                            <span>
                              <i class="fa fa-circle" aria-hidden="true"></i>
                            </span>
                            Who you Are{" "}
                          </a>
                          <hr style={{height: '4px', borderRadius: '4px', color: `#e6e9f0`, margin: '14px 0', border: `1px solid #e6e9f0`, backgroundColor: `#e6e9f0`}} />
                        </li>
                        <li>
                          <a href="#">
                            <span>
                              <i class="fa fa-circle" aria-hidden="true"></i>
                            </span>
                            Your Experience{" "}
                          </a>
                          <hr style={{height: '4px', borderRadius: '4px', color: `#e6e9f0`, margin: '14px 0', border: `1px solid #e6e9f0`, backgroundColor: `#e6e9f0`}} />
                        </li>
                        <li
                          class="active"
                          // style={
                          //   formColor !== ""
                          //     ? { borderBottom: `4px solid ${formColor}` }
                          //     : null
                          // }
                        >
                          <a href="#">
                            <span style={{ color: `${formColor}` }}>
                              <i class="fa fa-circle" aria-hidden="true"></i>
                            </span>
                            Your Preferences{" "}
                          </a>
                          <hr style={{height: '4px', borderRadius: '4px', color: `${formColor}`, margin: '14px 0', border: `1px solid ${formColor}`, backgroundColor: `${formColor}`}} />
                        </li>
                      </ul>
                    </div>
                    <div class="formbg relative">
                      <form id="">
                        <div
                          className="circle"
                          style={
                            formColor !== ""
                              ? { backgroundColor: `${formColor}` }
                              : null
                          }
                        >
                          <img src={Clock} />
                          <div>
                            <b>Estimated:</b>
                          </div>
                          <div>1 mins left</div>
                        </div>
                        <div class="form-row">
                          <div class="col-md-12 mb-3">
                            <div class="mb-23">
                              <label for="">
                                What’s your next <b>target career level?</b>
                              </label>
                            </div>
                            <div class="d-sm-flex align-items-center justify-content-between flex-wrap">
                              <div class="flex1 flex180">
                                <label for="mid" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="midlevel"
                                    style={{cursor: 'pointer'}}
                                    value={"Mid"}
                                    checked={career.includes("Mid")}
                                    onChange={(e) => {
                                      handleCareerWork(e);
                                    }}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    />
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    />
                                  </svg>
                                  <span class="checkbox__label">Mid</span>
                                </label>
                              </div>
                              <div class="flex1 flex180">
                                <label for="Sinior" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Siniorlevel"
                                    style={{cursor: 'pointer'}}
                                    value={"Senior"}
                                    checked={career.includes("Senior")}
                                    onChange={(e) => {
                                      handleCareerWork(e);
                                    }}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    />
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    />
                                  </svg>
                                  <span class="checkbox__label">Senior</span>
                                </label>
                              </div>
                              <div class="flex1 flex180" >
                                <label for="Lead" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Leadlevel"
                                    style={{cursor: 'pointer'}}
                                    value={"Lead/Principal"}
                                    checked={career.includes("Lead/Principal")}
                                    onChange={(e) => {
                                      handleCareerWork(e);
                                    }}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    />
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    />
                                  </svg>
                                  <span class="checkbox__label">
                                    Lead/Principal
                                  </span>
                                </label>
                              </div>
                              <div class="flex1 flex180">
                                <label for="Manager" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Managerlevel"
                                    style={{cursor: 'pointer'}}
                                    value={"Manager"}
                                    checked={career.includes("Manager")}
                                    onChange={(e) => {
                                      handleCareerWork(e);
                                    }}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    />
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    />
                                  </svg>
                                  <span class="checkbox__label">Manager</span>
                                </label>
                              </div>
                              <div class="flex1 flex180">
                                <label for="Head" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Headlevel"
                                    style={{cursor: 'pointer'}}
                                    value={"Head Of"}
                                    checked={career.includes("Head Of")}
                                    onChange={(e) => {
                                      handleCareerWork(e);
                                    }}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    />
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    />
                                  </svg>
                                  <span class="checkbox__label">Head Of</span>
                                </label>
                              </div>
                              <div class="flex1 flex180">
                                <label for="director" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="directorlevel"
                                    style={{cursor: 'pointer'}}
                                    value={"Director"}
                                    checked={career.includes("Director")}
                                    onChange={(e) => {
                                      handleCareerWork(e);
                                    }}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    />
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    />
                                  </svg>
                                  <span class="checkbox__label">Director</span>
                                </label>
                              </div>
                              <div class="flex1 flex180">
                                <label for="vp/gm" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="vp/gmlevel"
                                    style={{cursor: 'pointer'}}
                                    value={"VP/GM"}
                                    checked={career.includes("VP/GM")}
                                    onChange={(e) => {
                                      handleCareerWork(e);
                                    }}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    />
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    />
                                  </svg>
                                  <span class="checkbox__label">VP/GM</span>
                                </label>
                              </div>
                              <div class="flex1 flex180">
                                <label for="executive" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="executivelevel"
                                    style={{cursor: 'pointer'}}
                                    value={"Exec"}
                                    checked={career.includes("Exec")}
                                    onChange={(e) => {
                                      handleCareerWork(e);
                                    }}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    />
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    />
                                  </svg>
                                  <span class="checkbox__label">Executive</span>
                                </label>
                              </div>
                              <div class="flex1 flex180">
                                <label for="ned" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="nedlevel"
                                    style={{cursor: 'pointer'}}
                                    value={"NED/Chair"}
                                    checked={career.includes("NED/Chair")}
                                    onChange={(e) => {
                                      handleCareerWork(e);
                                    }}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    />
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    />
                                  </svg>
                                  <span class="checkbox__label">NED/Chair</span>
                                </label>
                              </div>
                              <div class="flex1 flex180" >
                                <label for="Founder" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Founderlevel"
                                    style={{cursor: 'pointer'}}
                                    value={"Founder"}
                                    checked={career.includes("Founder")}
                                    onChange={(e) => {
                                      handleCareerWork(e);
                                    }}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    />
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    />
                                  </svg>
                                  <span class="checkbox__label">
                                    Founder
                                  </span>
                                </label>
                              </div>
                              {careerError != "" && career.length == 0 && (
                                <div className="inline-flex items-baseline mt-2">
                                  <img className="self-center" src={Vector} />
                                  <h5 className="errorClass mt-1">
                                    {careerError}
                                  </h5>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="col-md-12 mb-3">
                            <div class="mb-23">
                              <label for="">
                                What <b>type of work</b> would you like to do?{" "}
                              </label>
                            </div>
                            <div class="d-sm-flex align-items-center justify-content-between flex-wrap">
                              <div class="flex1 flex140">
                                <label for="Full-Time" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Full-Timework"
                                    style={{cursor: 'pointer'}}
                                    value={"Full Time"}
                                    checked={typeOfwork.includes("Full Time")}
                                    onChange={(e) => handleCheckboxChange(e)}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">Full Time</span>
                                </label>
                              </div>
                              <div class="flex1 flex140">
                                <label for="Part-Time" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Part-Timework"
                                    style={{cursor: 'pointer'}}
                                    value={"Part Time"}
                                    checked={typeOfwork.includes("Part Time")}
                                    onChange={(e) => handleCheckboxChange(e)}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">Part Time</span>
                                </label>
                              </div>
                              <div class="flex1 flex140">
                                <label for="Consultancy" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Consultancywork"
                                    style={{cursor: 'pointer'}}
                                    value={"Consultancy"}
                                    checked={typeOfwork.includes("Consultancy")}
                                    onChange={(e) => handleCheckboxChange(e)}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">
                                    Consultancy
                                  </span>
                                </label>
                              </div>
                              <div class="flex1 flex140">
                                <label for="Advisory" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Advisorywork"
                                    style={{cursor: 'pointer'}}
                                    value={"Advisory"}
                                    checked={typeOfwork.includes("Advisory")}
                                    onChange={(e) => handleCheckboxChange(e)}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">Advisory</span>
                                </label>
                              </div>
                              {typeOfworkError != "" &&
                                typeOfwork.length == 0 && (
                                  <div className="inline-flex items-baseline mt-2">
                                    <img className="self-center" src={Vector} />
                                    <h5 className="errorClass mt-1">
                                      {typeOfworkError}
                                    </h5>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="col-md-12 mb-3">
                            <div class="mb-23">
                              <label for="">
                                What is your preferred <b>work environment? </b>
                              </label>
                            </div>
                            <div class="d-sm-flex align-items-center justify-content-between flex-wrap">
                              <div class="flex1 flex137">
                                <label for="No-remote" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="No-remoteenvironment"
                                    style={{cursor: 'pointer'}}
                                    value={"Fully in office"}
                                    checked={remoteWork.includes(
                                      "Fully in office"
                                    )}
                                    onChange={(e) => handleRemoteboxChange(e)}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">
                                    Fully in office
                                  </span>
                                </label>
                              </div>
                              <div class="flex1 flex137">
                                <label for="Hybrid" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Hybridenvironment"
                                    style={{cursor: 'pointer'}}
                                    value={"Hybrid"}
                                    checked={remoteWork.includes("Hybrid")}
                                    onChange={(e) => handleRemoteboxChange(e)}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">Hybrid</span>
                                </label>
                              </div>
                              <div class="flex1 flex137">
                                <label for="Fully-remote" class="checkbox" style={{cursor: 'default'}}>
                                  <input
                                    class="checkbox__input"
                                    type="checkbox"
                                    id="Fully-remoteenvironment"
                                    style={{cursor: 'pointer'}}
                                    value={"Fully remote"}
                                    checked={remoteWork.includes(
                                      "Fully remote"
                                    )}
                                    onChange={(e) => handleRemoteboxChange(e)}
                                  />
                                  <svg
                                    class="checkbox__icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                  >
                                    <rect
                                      width="21"
                                      height="21"
                                      x=".5"
                                      y=".5"
                                      fill="#FFF"
                                      stroke="transparent"
                                      rx="3"
                                    ></rect>
                                    <path
                                      class="tick"
                                      stroke={`${formColor}`}
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                      d="M4 10l5 5 9-9"
                                    ></path>
                                  </svg>
                                  <span class="checkbox__label">
                                    Fully remote
                                  </span>
                                </label>
                              </div>
                              {remoteWorkError != "" &&
                                remoteWork.length == 0 && (
                                  <div className="inline-flex items-baseline mt-2">
                                    <img className="self-center" src={Vector} />
                                    <h5 className="errorClass mt-1">
                                      {remoteWorkError}
                                    </h5>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="col-md-12 mb-3">
                            <div class="mb-23">
                              <label for="">
                                What <b>company stages</b> are you interested
                                in?
                              </label>
                            </div>
                            <div class="d-sm-flex align-items-center flex-wrap">
                            {FullStageModel?.map((place, index) => (
                                  <div class="w-full sm:w-1/2 md:w-1/3" key={place}>
                                    <label htmlFor={place} className="checkbox" style={{cursor: 'default'}}>
                                      <input
                                        className="checkbox__input"
                                        type="checkbox"
                                        id={place + "stages"}
                                        style={{cursor: 'pointer'}}
                                        value={place}
                                        checked={stage.includes(place)}
                                        onChange={(e) => handleStagesArray(e)}
                                      />
                                      <svg
                                        className="checkbox__icon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                      >
                                        <rect
                                          width="21"
                                          height="21"
                                          x=".5"
                                          y=".5"
                                          fill="#FFF"
                                          stroke="transparent"
                                          rx="3"
                                        />
                                        <path
                                          className="tick"
                                          stroke={`${formColor}`} // Assuming formColor is defined
                                          fill="none"
                                          strokeLinecap="round" // Corrected attribute name
                                          strokeWidth="1" // Corrected attribute name
                                          d="M4 10l5 5 9-9"
                                        />
                                      </svg>
                                      <span className="checkbox__label">
                                        {place}
                                      </span>
                                    </label>
                                  </div>
                                ))}
                              {stageError != "" && stage.length == 0 && (
                                <div className="inline-flex items-baseline mt-2">
                                  <img className="self-center" src={Vector} />
                                  <h5 className="errorClass mt-1">
                                    {stageError}
                                  </h5>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="w-full mb-4">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-2"
                            for="password"
                          >
                            Anything else you’d like{" "}
                            <strong>employers to know?</strong>
                          </label>
                          <p className="text-sm md:text-base text-gray-800 mb-4">
                            You can use this section to give us more details
                            about your strengths, experience, and/or the types
                            of roles you’re interested in. Employers will see
                            this alongside your career preferences.
                          </p>
                          <textarea
                            id="textarea"
                            placeholder={`I have extensive experience scaling B2B global businesses. I have exited one business and scaled another to $100m in annual revenue.
                                 
My background includes building and leading high performing teams and accelerating business performance.

I’m looking for US based COO roles at growth companies. Must have dogs. 🐶`}
                            rows="11"
                            maxlength="1500"
                            onChange={(e) => handleCharCount(e)}
                            style={{
                            // padding: '10px',
                            height: "236px",
                            border: "1px solid rgb(230, 233, 240)",
                            transition: "border-color 0.3s ease-in-out",
                            outline: "none",
                            boxShadow: `0 0 0 3px transparent`,
                          }}
                          onFocus={(e) => {
                            e.target.style.borderColor = `${formColor}`; // Change border color on focus
                            e.target.style.boxShadow = `${formColor}`; // Change focus ring color on focus
                          }}
                          onBlur={(e) => {
                            e.target.style.borderColor = "rgb(230, 233, 240)"; // Reset border color on blur
                            e.target.style.boxShadow = "0 0 0 3px transparent"; // Reset focus ring color on blur
                          }}
                            className="appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:bg-white transition ease-in-out"
                          >
                            {textArea}
                          </textarea>
                          <p class="number-left">{CharCount} / 1500</p>
                          <div className="mainCharDiv">
                            {CharCount >= 1500 && (
                              <p className="char_text">
                                You have reached the character limit.
                              </p>
                            )}
                            {/* <h5 className="char_count">{CharCount}/600</h5> */}
                          </div>
                        </div>
                        <div className="w-full mb-4 mt-5">
                          <label
                            className="block text-base md:text-lg font-normal text-gray-1000 mb-2"
                            for="password"
                          >
                            Who <strong>referred you</strong> to our network?
                          </label>
                          <p className="text-sm md:text-base text-gray-800 mb-4">
                            Please add the email address of the person who
                            shared this form with you. We’d like to let them
                            know that you’ve completed it.
                            </p>
                            <p className="text-sm md:text-base text-gray-800 mb-4">
                            If you're applying via our website please add <strong>{formVCname.toLowerCase().replace(/\s/g, '')}@resumedrop.com</strong>
                          </p>
                          <input
                            type="text"
                            value={referredBy}
                            onChange={(e) => {
                              setreferredBy(e.target.value);
                            }}
                            style={{
                            // padding: '10px',
                            border:
                            referredBy == email && referredByError != "" ||
                            referredBy == "" && referredByError != "" ||
                            referredBy != "" &&
                                !referredBy.includes("@") &&
                                referredByError != ""
                                ? "1px solid red"
                                : "1px solid rgb(230, 233, 240)",
                            transition: "border-color 0.3s ease-in-out",
                            outline: "none",
                            boxShadow: `0 0 0 3px transparent`,
                          }}
                          onFocus={(e) => {
                            e.target.style.borderColor = `${formColor}`; // Change border color on focus
                            e.target.style.boxShadow = `${formColor}`; // Change focus ring color on focus
                          }}
                          onBlur={(e) => {
                            e.target.style.borderColor = "rgb(230, 233, 240)"; // Reset border color on blur
                            e.target.style.boxShadow = "0 0 0 3px transparent"; // Reset focus ring color on blur
                          }}
                            className={
                              (referredBy == email && referredByError != "") ||
                              (referredBy == "" && referredByError != "") ||
                              (referredBy != "" &&
                                !referredBy.includes("@") &&
                                referredByError != "")
                                ? "appearance-none w-full text-lg leading-6 bg-white font-normal text-gray-800 placeholder:text-gray-400 rounded-lg py-3 px-4 border border-red-500 focus:outline-none focus:ring-1 focus:ring-deep-purple-500 focus:bg-white focus:border-deep-purple-500 transition duration-500"
                                : "appearance-none block w-full py-2.5 px-4 text-base md:text-lg font-normal text-gray-800 placeholder:text-gray-400 bg-white bg-clip-padding border border-gray-300 rounded-lg m-0 focus:outline-none focus:bg-white transition ease-in-out"
                            }
                            id="refferedBy"
                            placeholder="person@company.com"
                          />
                          {(referredBy == email && referredByError != "") ||
                          (referredBy == "" && referredByError != "") ||
                          (referredBy != "" &&
                            !referredBy.includes("@") &&
                            referredByError != "") ? (
                            <div className="inline-flex items-baseline mt-2">
                              <img className="self-center" src={Vector} />
                              <h5 className="errorClass">{referredByError}</h5>
                            </div>
                          ) : null}
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-check form-check-inline mr-32">
                      <label for="termAndCondition" className="checkbox">
                        <input
                          className="checkbox__input"
                          type="checkbox"
                          id="termAndCondition"
                          checked={terms}
                          onChange={(e) => handleTerm(e)}
                        />
                        <svg
                          className="checkbox__icon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 22 22"
                        >
                          <rect
                            width="21"
                            height="21"
                            x=".5"
                            y=".5"
                            fill="#FFF"
                            stroke="transparent"
                            rx="3"
                          ></rect>
                          <path
                            className="tick"
                            stroke={`${formColor}`}
                            fill="none"
                            stroke-linecap="round"
                            stroke-width="1"
                            d="M4 10l5 5 9-9"
                          ></path>
                        </svg>
                        <span className="checkbox__label">
                          Terms and conditions*
                        </span>
                      </label>
                    </div>
                    {/* OrgName == "OctopusVentures" ? "OctopusVentures" : OrgName == "OakHCFT" ? "Oak HC/FT" : OrgName == "M13" ? "M13" : OrgName == "HumanCapital" ? "Human Capital" : OrgName == "GVCTN" ? "Global VC Talent Network" : "admin", */}
                    <p style={{ paddingBottom: "10px" }}>
                      I hereby grant{" "}
                      {formVCname}{" "}
                      consent to collect data for my candidate profile and use
                      it for this purpose only. For further information on how
                      we collect, manage and store your personal data please
                      read our{" "}
                      <a
                        className="text-deep-purple-500"
                        style={{color: `${formColor}`}}
                        // href={"javascript:void(0)"}
                        // onClick={handlePrivacyPolicy}
                        href={privacyPolicy}
                        target="_blank"
                        // href={
                        //   OrgName == "OctopusVentures"
                        //     ? "https://octopusventures.com/privacy-policy/"
                        //     : "https://www.startuplane.io/legal/privacy"
                        // }
                      >
                        Privacy Policy
                      </a>
                    </p>
                    {TNCError != "" && terms == false && (
                      <div className="inline-flex items-baseline mt-2">
                        <img className="self-center" src={Vector} />
                        <h5 className="errorClass">{TNCError}</h5>
                      </div>
                    )}
                    <div class="d-sm-flex justify-content-center btn-gap text-center ">
                      <div>
                        <button
                          className="btn back mt-5 mb-4"
                          type="submit"
                          style={{color: `${formColor}`}}
                          onClick={(e) => handleClickPrev(e)}
                        >
                          Back
                        </button>
                      </div>
                      <div>
                        <button
                          className="btn submit mt-5 mb-4"
                          type="submit"
                          style={{backgroundColor: `${formColor}`}}
                          onClick={(e) => handleFormSubmit(e)}
                        >
                          {FormLoading == true ? (
                            <div className="flex">
                              <Image27 className="spinner mr-2 mt-1" />{" "}
                              Submitting
                              {/* <i class="fa fa-spinner spinner mr-2" aria-hidden="true" /> */}
                            </div>
                          ) : (
                            <>Submit</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {screen == 4 && (
              <>
                {/* Thank you Start */}
                <h2 className="text-2xl md:text-3xl font-bold mb-2 mt-5">
                  Nicely Done 🎉
                </h2>
                <hr className="my-4 -mx-5" />
                <p className="text-base md:text-lg mb-4">
                Thank you for joining our network!
                </p>
                <p className="text-base md:text-lg mb-4">
                We’ll be in touch with any relevant opportunities in our portfolio based on your experience and preferences.
                </p>
                {(() => {
                        if (OrgName == "OctopusVentures") {
                          return (
                            <img
                            className="w-60 h-60 rounded-full mx-auto"
                            src="https://i.giphy.com/media/WwMHQqMG0yirepFPP8/giphy.webp"
                            alt="Octopus Ventures"
                            width="48"
                            height="48"
                          />
                          )
                        } else if (OrgName == "CleanEnergyVentures") {
                          return (
                            <img
                            className="h-48 mx-auto"
                            src="http://cnp1656-prod.s3.amazonaws.com/static/CEV-Welcome.jpg"
                            alt="CleanEnergyVentures"
                            height="48"
                          />
                          )
                        } else {
                          return (
                            <img
                            className="w-full"
                            src="https://i.giphy.com/media/l41YmQjOz9qg2Ecow/giphy.webp"
                            alt={OrgName}
                            width="48"
                            height="48"
                          />
                          )
                        }
                      })()}
                {/* Thank End */}
              </>
            )}
            {screen == 1 && (
              <div className="col-md-12 mb-5 text-center">
                <button
                  className="btn submit"
                  type="submit"
                  style={{backgroundColor: `${formColor}`}}
                  onClick={(e) => handleClickNextscreen1(e)}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
        {screen != 4 && (
          <div className={"FooterImage"} style={{backgroundColor: `${formColor}`}}>
            <h1 style={{ minHeight: "30px" }}></h1>
          </div>
        )}
      </div>
      </>
    )}
    </>
  );
};

const mapStateToProps = ({ authentication }) => ({
  authentication_state: authentication,
});

export default connect(mapStateToProps)(Forms);
